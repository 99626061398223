import { selectedUserSelector } from 'app/rkt_query/SelectedStuffReducer';
import {  useGetUserQuery } from 'app/rkt_query/storeApis';
import React from 'react'
import { useSelector } from 'react-redux';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewUser = () => {

    const selectedUser = useSelector(selectedUserSelector);

    const userArray =
        [
            { label: 'Name', data: selectedUser?.name },
            { label: 'Email', data: selectedUser?.email },
            { label: 'Mobile', data: selectedUser?.mobile },
        ]

    return (
        <div>

            {
                  selectedUser?.id ?
                        <DZGenericView obj={userArray} closeBtn={true}  /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewUser

