import { selectedUserProjectSelector } from 'app/rkt_query/SelectedStuffReducer';
import {  useGetUserProjectQuery } from 'app/rkt_query/storeApis';
import React from 'react'
import { useSelector } from 'react-redux';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewUserProject = () => {

    const selectedUserProject = useSelector(selectedUserProjectSelector);
    const { data: res_get } = useGetUserProjectQuery(selectedUserProject?.id);
    const userProjectArray =
        [
            { label: 'User Name', data: res_get?.data?.userProject?.user?.name },
            { label: 'User Email', data: res_get?.data?.userProject?.user?.email },
            { label: 'UserProject', data: res_get?.data?.userProject?.name },
        ]

    return (
        <div>

            {
                  selectedUserProject?.id ?
                        <DZGenericView obj={userProjectArray} closeBtn={true}  /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewUserProject

