import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import {
  Button,
  TextField,
  Fab,
  Box,
  Radio,
  FormControlLabel,
  RadioGroup,
  Grid,
} from "@mui/material";
import {
  selectedProjectSelector,
  setSelectedProject,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddProjectMutation,

  useGetAllUsersQuery,

  useGetProjectQuery,
  useUpdateProjectMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";


import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import Div from "@jumbo/shared/Div";


const AddUpdateProject = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedProject = useSelector(selectedProjectSelector);
  const { data: res_get_all, isLoading: isLoadingAdmins, error: errorAdmins } = useGetAllUsersQuery();
  const { data: res_get } = useGetProjectQuery(selectedProject?.id);

  const [updateProject, responseUpdate] = useUpdateProjectMutation();
  const [addProject, responseAdd] = useAddProjectMutation();
  const bluePrint = BLUE_PRINTS.project;
  const [projectState, setProjectState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();

  const addUpdateProjectData = async (action) => {
    const formData = new FormData();

    formData.append("id", res_get?.data?.project?.id);
    formData.append("name", projectState.name);
    formData.append("user_id", projectState.user_id);
  

    const res =
      action === "add"
        ? await addProject(formData)
        : await updateProject(formData);

    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message, "error");
    }
  };
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    setProjectState(selectedProject ? res_get?.data?.project : bluePrint);
  }, [res_get]);

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {projectState ? (
        <div style={addUpdateDivStyleGlobal}>
         
          <DZTextValidation
            error={error?.name}
            label="Project Name"
            variant="outlined"
            value={projectState?.name}
            onChange={(e) => {
              setProjectState((x) => ({ ...x, name: e.target.value }));
            }}
          
          />
     

     <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZDropdown
                error={error?.user_id}
                value={projectState?.user_id || ""}
                data={res_get_all?.data?.users}
                onChange={(e) => {
                  setProjectState((x) => ({
                    ...x,
                    user_id: e.target.value,
                  }));
                }}
                fieldTextName="email"
                placeholder="Select User"
              />
            </Div>
          </Grid>

     

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedProject(null));
              }}
            >
              Cancel
            </Button>
            {selectedProject?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateProjectData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateProjectData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedProject(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateProject;
