/* eslint-disable default-case */
/* eslint-disable no-lone-blocks */
import { BLUE_PRINTS, useGetAllModulesQuery } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { selectedLanguageSelector } from '../../../app/rkt_query/SettingsReducer';
import { Article, Delete, Edit, MenuOpen, Visibility } from '@mui/icons-material';
import HeaderButtons, { SimpleButtons } from '@jumbo/dz-components/header-buttons/HeaderButtons';
import { useJumboDialog } from '../../components/JumboDialog/hooks/useJumboDialog';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import './app.css';
import { useNavigate } from 'react-router-dom';
import ComboBox from '../autocomplete-cb/ComboBox';
import JumboDdMenu2 from '@jumbo/components/JumboDdMenu/JumboDdMenu2';
import { useLocation } from 'react-router-dom';
import { resetSettingsReducer, selectedIdSelector, setselectedId } from 'app/rkt_query/GlobalReducer';
// import { boxSX, filterNavigationData, handleActionButtons, handleDDData, handleDDOptionChange, handleLanguage, handleVisibilityAndAddIcon, selectDDHeader } from './GlobalFunctions';
import HeirarchyFlow from './HeirarchyFlow';
import Diversity1Icon from '@mui/icons-material/Diversity1';
// import { handleSelectedDDItem } from './GlobalFunctions';
// import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
// import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
// import AppUpdateTopic from 'app/pages/topic/AppUpdateTopic';
// import LearningJourney from 'app/pages/drning-journey/LearningJourney';
// import ViewModule from 'app/pages/module/ViewModule';
// import Module from 'app/pages/module/AddUpdateModule';
// import { AddOutlined, ControlPointDuplicateRounded, PhotoCamera } from '@mui/icons-material';
// import { setSelectedProduct, setSelectedCategory } from 'app/rkt_query/SelectedStuffReducer';
// import Activity from 'app/pages/activity/Activity';
// import Company from 'app/pages/company/Company';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, CircularProgress } from '@mui/material';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import { Table, TableBody } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import FastfoodIcon from '@mui/icons-material/Fastfood'; 
import ImageIcon from '@mui/icons-material/Image';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import ActivityOrdering from 'app/pages/activity/ActivityOrdering';
import SendIcon from '@mui/icons-material/Send';
import StoreTokenCheck from './../../../app/pages/storetoken/StoreTokenCheck';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import ChatIcon from '@mui/icons-material/Chat';
import RestoreIcon from '@mui/icons-material/Restore';

const DataTable = ({
  onRow = () => { },
  goToPage = '',
  showHeaderAddButton = false,
  addAndViewTitle,
  showHideViewTitle = true,
  showHideLeadTitle = true,
  showHideLeadOfferSendTitle = true,
  showHideLeadSlug = true,
  showHideChatUser = true,
  onlyDeleteIcon = false,
  
  showRestoreBtn = false,
  hideEditBtn = true,
  hideViewBtn = true,
  hideDeleteBtn = true,
  showAddButtonContract = true,
  showHideContractPassengerSlug = true,
  showHideAddContractDetail = true,
  showHideChangePassenger = true,
  addContractDetailTitle,
  contractPassengerTitle,
  changePassengerTitle,
  addContractTitle,
  viewTitle,
  leadTitle,
  leadSlug,
  showAddButton,
  HeaderAddButton = true,
  leadSendTitle,
  chatTitle,
  btnTitleAdd,
  btnTitleAdd1,
  data,
  bluePrint,
  showLanguageSwitcher,
  ImageDzDrop = () => { },
  ModalContent = () => { },
  ModalRelationContent = () => { },
  ViewModel = () => { } }) => {

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const selectedId = useSelector(selectedIdSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showDialog, hideDialog } = useJumboDialog();
  const [rows, setRows] = useState(data);
  const [pageSize, setPageSize] = React.useState(10);
  const columns = [...bluePrint.getCols(selectedLanguage),
  {
    field: addAndViewTitle, headerName: addAndViewTitle, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showAddButton,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
      // console.log("this row ", params?.row)
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {

            (
              (params?.row?.hotel_sports?.[0]) &&
              <SportsSoccerIcon onClick={() => handleGoToHotelSports(thisRow)} />
            )

          }
       
        </div>
      )
    }
  },
  {
    field: addContractTitle, headerName: addContractTitle, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showAddButtonContract,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
      // console.log("this row ", params?.row)
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>

          {
            btnTitleAdd === "LeadOffer" &&
            (
              //last one is completed
              params?.row?.status == 'success' && <QueuePlayNextIcon onClick={() => handleItemAction({ title: "Add Contract", action: "addContract", row: thisRow })} />
            )
          }
        </div>
      )
    }
  },

  {
    field: viewTitle, headerName: viewTitle, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showHideViewTitle,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

      // console.log("this row ", params?.row?.courses?.[0])
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {/* <ControlPointOutlinedIcon /> */}
          {
            <ImageIcon onClick={() => handleOpenImage(thisRow)} />

          }
        </div>
      )
    }
  },
 
  {
    field: chatTitle, headerName: chatTitle, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showHideChatUser,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};
      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
       
          {
         
            <ChatIcon onClick={() => handleItemAction({ title: "Chat", action: "chat", row: thisRow })} />

          }
        </div>
      )
    }
  },
  {
    field: leadSendTitle, headerName: leadSendTitle, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showHideLeadOfferSendTitle,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

      // console.log("this row ", params?.row?.courses?.[0])
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {/* <ControlPointOutlinedIcon /> */}
          {
            <SendIcon onClick={() => handleSendLeadOffer(thisRow)} />

          }
        </div>
      )
    }
  },
  {
    field: leadSlug, headerName: leadSlug, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showHideLeadSlug,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

      // console.log("this row ", params?.row?.courses?.[0])
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {/* <ControlPointOutlinedIcon /> */}
          {
            <OpenInNewIcon onClick={() => handleGoToLeadSlug({ title: "offer-contract", action: "offerContract", row: thisRow })} />

          }
        </div>
      )
    }
  },
  {
    field: changePassengerTitle, headerName: changePassengerTitle, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showHideChangePassenger,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

      // console.log("this row ", params?.row?.courses?.[0])
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {/* <ControlPointOutlinedIcon /> */}
          {
            <ContentPasteGoIcon onClick={() => handleGoToLeadSlug({ title: "change-passenger", action: "passenger", row: thisRow })} />

          }
        </div>
      )
    }
  },
  {
    field: contractPassengerTitle, headerName: contractPassengerTitle, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showHideContractPassengerSlug,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

      // console.log("this row ", params?.row?.courses?.[0])
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {/* <ControlPointOutlinedIcon /> */}
          {
            <Diversity1Icon onClick={() => handleGoToLeadSlug({ title: "contract-passenger", action: "passenger", row: thisRow })} />

          }
        </div>
      )
    }
  },
  // {
  //   field: addContractDetailTitle, headerName: addContractDetailTitle, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showHideAddContractDetail,
  //   renderCell: (params) => {
  //     const api = params.api;
  //     const thisRow = {};

  //     api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

  //     // console.log("this row ", params?.row?.courses?.[0])
  //     return (
  //       <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
  //         {/* <ControlPointOutlinedIcon /> */}
  //         {
  //          <ContentPasteGoIcon  onClick={() => handleGoToLeadSlug({ title: "add-contract-detail", action: "contract", row: thisRow })} /> 

  //         }
  //       </div>
  //     )
  //   }
  // },
  {

    field: "action", headerName: "Actions", sortable: false, flex: 1, align: 'center', headerAlign: 'center',
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

      return (

         <Box sx={{ display: 'flex', gap: 2 }}>
          {hideViewBtn && <Visibility onClick={() => handleItemAction({ title: "View", action: "view", row: thisRow })} />}
          {hideEditBtn && <Edit onClick={() => handleItemAction({ title: "Edit", action: "edit", row: thisRow })} />}
          {hideDeleteBtn && <Delete onClick={() => handleItemAction({ title: "Delete", action: "delete", row: thisRow })} />}
          {showRestoreBtn&&(thisRow?.deleted_at ?<RestoreIcon onClick={() => handleItemAction({ title: "Restore", action: "restore", row: thisRow })} />: <Delete onClick={() => handleItemAction({ title: "Delete", action: "delete", row: thisRow })} />)}
        </Box>

       
      )
    }
  },
  ];

  useEffect(() => {
    //console.log("rows_rows", data[0]?.filter((item) => typeof Number(item?.company_id)))
    setRows(data);
    // setRows(filterNavigationData(relationalObject, data, btnTitleAdd))
    // { (btnTitleAdd === "Course") && setRows((relationalObject) ? (data?.filter((item) => item?.company_id == relationalObject?.id)) : data) }
    //{ heirarchyId && setRows((heirarchyId) ? (data?.filter((item) => item?.id === heirarchyId)) : data) }
  }, [data])

  const handleUpdateAllRows = (e) => {
    console.log("e", e)
    // var ch = handleDDOptionChange(btnTitleAdd, e, ddTranslate, data, selectedLanguage, getAllData)
    // setRows(ch)
    { !e && dispatch(resetSettingsReducer()) }
  };

  // const list = handleDDData(ddTranslate, listData, bluePrint, selectedLanguage)


  const handleSelectToo = (menuItem) => {

    // handleVisibilityAndAddIcon(menuItem, navigate, dispatch, btnTitleAdd, showDialog, relationalTitle)
  }




  const handleOpenImage = (menuItem) => {
    const hotelName = menuItem?.name
    bluePrint.editRecord(menuItem);


    showDialog({
      title: btnTitleAdd + 'Image',
      size,
      content: <ImageDzDrop hotelName={hotelName}  />
    });

  }


  const handleGoToCrud = (menuItem) => {

    switch (btnTitleAdd) {
      case "Product":
        navigate("/product-accessories", {
          state: {
            relationalObject: menuItem,
          },
        });
        break;

      default:
        break;
    }

  }
  const deleteCourseUser = (menuitem) => {

    showDialog({
      variant: 'confirm',
      title: 'Are you sure about deleting this ' + btnTitleAdd + '?',
      content: "You won't be able to recover this " + btnTitleAdd + ' later',
      onYes: () => handleDeleteDialogue(menuitem.id),
      onNo: hideDialog
    })
  }

  // handle action buttons
  const handleItemAction = (menuItem) => {
    // eslint-disable-next-line default-case
      // console.log("handle", menuItem.row.user_id?.[0])

    const size = "xl";

    switch (menuItem.action) {
      case 'view':

        bluePrint.showRecord(menuItem.row);
        showDialog({
          title: btnTitleAdd + ' Detail',
          size,
          showLanguageSwitcher: showLanguageSwitcher,
          content: <ViewModel />
        });


        break;
     
      case 'edit': 

        bluePrint.editRecord(menuItem.row);
        if (goToPage) {

          navigate(goToPage, {
            state: { hotelId: menuItem?.row?.id }
          })

        } else {


          showDialog({
            title: 'Update ' + btnTitleAdd,
            size,
            showLanguageSwitcher: showLanguageSwitcher,
            content: <ModalContent />
          });
        }
        break;
      case 'delete':
        showDialog({
          showLanguageSwitcher: false,
          variant: 'confirm',
          title: 'Are you sure about deleting this ' + btnTitleAdd + '?',
          content: "You won't be able to recover this " + btnTitleAdd + ' later',
          onYes: () => handleDeleteDialogue(menuItem.row.id),
          onNo: hideDialog
        })
        break;
      case 'restore':
        showDialog({
          showLanguageSwitcher: false,
          variant: 'confirm',
          title: 'Are you sure Restore ' + btnTitleAdd + '?',
          onYes: () => handleDeleteDialogue(menuItem.row.id),
          onNo: hideDialog
        })
        break;
      case 'addContract':
        bluePrint.showRecord(menuItem.row);
        showDialog({
          title: "Add New " + btnTitleAdd1,
          size,
          content: <ModalRelationContent />
        });


        break;

    }
    // handleActionButtons(menuItem, btnTitleAdd, ViewModel, dispatch, navigate, bluePrint, showDialog, ModalContent)
  };

  const handleDeleteDialogue = (id) => {
    bluePrint.handleDelete(id);
    hideDialog();
  }
  const handleSendDialogue = (id) => {
    bluePrint.handleSend(id);
    hideDialog();
  }

  const handleRowClick = (params) => {
    console.log(params)
    onRow(params)
  };

  const size = "xl";
  const showAddDialog = React.useCallback(() => {
    showDialog({
      title: "Add " + btnTitleAdd,
      size,
      showLanguageSwitcher: showLanguageSwitcher,
      content: <ModalContent />
    });
  }, [showDialog]);





  const handleSendLeadOffer = (menuItem) => {
    showDialog({
      showLanguageSwitcher: false,
      variant: 'confirm',
      title: 'Are you sure about Send this ' + btnTitleAdd + '?',
      onYes: () => handleSendDialogue(menuItem.id),
      onNo: hideDialog
    })

  }
  const handleGoToLeadSlug = (menuItem) => {

    bluePrint.handleGoTo(menuItem);


  };
  const handleGoToHotelSports = (menuItem) => {

    console.log(menuItem?.id);
    navigate("/hotel/sports", {
      state: { hotelId: menuItem?.id }
    })

  }

  return (
    <div>
      <StoreTokenCheck />

      <div style={{ width: '100%' }}>
        <div style={{ marginLeft: '10px', marginRight: '10px', display: 'flex', flexDirection: 'row', width: '100%' }} >

          {/* {(showAutoComplete && list) && (
            <div style={{ marginTop: '0px', display: 'flex', justifyContent: 'flex-start' }}>
              <ComboBox
                btnTitleAdd={btnTitleAdd}
                data={list}
                onChange={(event, value) => { handleUpdateAllRows(value); setUndo(false) }}
                //checkUndo={undo}
                value={ddName ? ddName : "Company"}
                defValue={input}
                inputChange={(e, val, reason) => setInput(val)}
                width={'200%'}
              />
            </div>)
          } */}
          {/* for showing header ADD button  */}
          {/* {(btnTitleAdd === "Category" || btnTitleAdd === "Course" || btnTitleAdd === "User" || btnTitleAdd === "Admin") && */}

          {HeaderAddButton && <div style={{ marginTop: '0px', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            { <HeaderButtons onClick={() => { showAddDialog(); bluePrint.addRecord(); }} />}
          </div>}
          {/* } */}
          {/* {(btnTitleAdd === "Activity") && <SimpleButtons title={"Activity Ordering"} onClick={() => { showOrderingDialog(); }} />} */}

        </div>
      </div>
      <div style={{ height: 600, width: '100%', padding: '0px 20px 0px 20px', marginTop: '2%' }}>
        {rows ?
          <>

            <DataGrid
              sx={{
                '& .MuiDataGrid-toolbarContainer': {
                  pb: 2
                },
                '& MuiDataGrid-toolbarContainer-toolbarQuickFilter': {
                  m: 20,
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              rows={rows?.map((obj) => bluePrint.translate(obj, selectedLanguage))}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[5, 10, 20]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onRowClick={handleRowClick}
              pagination
              checkBoxSelection
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </>
          :
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        }
      </div>
    </div>
  )
}

export default DataTable