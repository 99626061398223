import React from "react";

import Admin from "../pages/admin";
import User from "../pages/user";
import Login2 from "../pages/auth/login2/index"
import Dashboard from "app/pages/dashboard/Dashboard";
import Login from './../pages/auth/login/Login';
// import UserProfile from './../pages/users/user-profile/UserProfile';
import ForgotPassword from './../pages/auth/forgot-password/ForgotPassword';
import ForgotPasswordCode from './../pages/auth/forgot-password-code/ForgotPasswordCode';
import ChangePassword from './../pages/auth/forgot-password-code/ChangePassword';
import Profile from './../pages/profile/index';
import ProjectList from "app/pages/project";
import UserProjectList from "app/pages/user project";


const routes = [

    { path: "/", element: <Login /> },
    { path: "forgot-password",element: <ForgotPassword />  },
    { path: "verify-forgot-password", element: <ForgotPasswordCode /> },
    { path: "change-password", element: <ChangePassword />},
    { path: "/login", element: <Login2 />},
    { path: "/profile",element: <Profile />},
    { path: "/dashboard",  element: <Dashboard />},
    { path: "/project",  element: <ProjectList />},
    { path: "/user-project",  element: <UserProjectList />},
    
     
     {path: "/admins", element: <Admin /> },
       { path: "/users",element: <User />},
];

export default routes;