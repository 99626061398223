import React, { useState } from "react";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
import { auth } from "@jumbo/services/auth/firebase/firebase";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_AVATARS, ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { Config } from "./../../../../constant/index";
import PP2Service from "./../../../services/index";
import { LoginSnackbar } from "@jumbo/dz-components/snackbar/Snackbar";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useUserLoginMutation } from "app/rkt_query/storeApis";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedUserLoginSelector,
  setSelectedUserLogin,
} from "app/rkt_query/SelectedStuffReducer";
import CryptoJS from "crypto-js";
import {
  decryptStoredData,
  encryptStoredData,
} from "app/pages/storetoken/StoreTokenCheck";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login = () => {
  const dispatch = useDispatch();

  const { setActiveLayout } = useJumboApp();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [userLogin, userLoginResponse] = useUserLoginMutation();
  const selectedUserLogin = useSelector(selectedUserLoginSelector);

  const navigate = useNavigate();
  const storedToken = decryptStoredData(Config.adminApiTokenName);
  React.useEffect(() => {
    // const decrypt_token = localStorage.getItem(Config.adminApiTokenName)
    // const bytes = CryptoJS.AES.decrypt(decrypt_token, Config.secretPass);
    // JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    console.log(storedToken);

    if (storedToken) {
      console.log(" in trored token condition");

      navigate("/project");
    }
    console.log(" Out trored token condition");

    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
  }, [storedToken]);
  const handleToClose = (event, reason) => {
    if ("clickaway" == reason) return;
    setOpen(false);
  };
  // const handler = async () => {
  const handler = () => {
    console.log("login attempted");
    setLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    userLogin(formData)
      .unwrap()
      .then((payload) => {
        console.log("response from server");
        console.log(payload);

        dispatch(
          setSelectedUserLogin(payload.data?.admin?.role?.role_name?.name)
        );
        // const encrypt_token = CryptoJS.AES.encrypt(
        //     JSON.stringify(payload.data.access_token),
        //     Config.secretPass
        //   ).toString();

        encryptStoredData(Config.adminApiTokenName, payload.data.access_token);
        encryptStoredData(
          Config.roleName,
          payload.data?.admin?.role?.role_name?.name
        );

        setLoading(false);

        navigate("/dashboard", { replace: true });
      })
      .catch((error) => {
        setLoading(false);
        setResponse(false);
        setOpen(true);
      });

    //    old code without rtk query
    // let response = await PP2Service.fetch("admin/login", "post", {
    //     email: email,
    //     password: password,
    //     device_name: "test",
    // });

    // if (response.success) {
    //     // Saved token to local storage

    //     console.log("response>Json.access_token::", response.data.access_token);
    //     localStorage.setItem(
    //         Config.adminApiTokenName,
    //         response.data.access_token
    //     );
    //     setLoading(false);
    //     navigate("/hotels");

    // } else {
    //     setLoading(false);
    //     setResponse(false);
    //     setOpen(true);

    // }
  };
  const mutation = useAuthSignInWithEmailAndPassword(auth, {
    onError(error) {
      console.log(error);
    },
    onSuccess(data) {
      navigate("/", { replace: true });
    },
  });

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleToClose}
      >
        <Alert onClose={handleToClose} severity="error" sx={{ width: "100%" }}>
          {"Wrong Credential"}
        </Alert>
      </Snackbar>
      <Div
        sx={{
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: (theme) => theme.spacing(4),
        }}
      >
        <Div sx={{ mb: 3, display: "inline-flex" }}>
          <Link href="#" underline="none" sx={{ display: "inline-flex" }}>
            <img
              src={`${ASSET_IMAGES}/logo.png`}
              width="172px"
              height="73px"
              alt="Jumbo React"
            />
          </Link>
        </Div>
        <Card sx={{ maxWidth: "100%", width: 360, mb: 4 }}>
          <Div sx={{ position: "relative", height: "200px" }}>
            <CardMedia
              component="img"
              alt="green iguana"
              height="200"
              image={`${ASSET_IMAGES}/colin-watts.jpg`}
            />
            <Div
              sx={{
                flex: 1,
                inset: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                backgroundColor: (theme) =>
                  alpha(theme.palette.common.black, 0.5),
                p: (theme) => theme.spacing(3),
              }}
            >
              <Typography
                variant={"h2"}
                sx={{
                  color: "common.white",
                  fontSize: "1.5rem",
                  mb: 0,
                }}
              >
                Sign In
              </Typography>
            </Div>
          </Div>
          <CardContent sx={{ pt: 0 }}>
            <Formik>
              <Div style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Div sx={{ mb: 3, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="email"
                    label="Email"
                    onChange={(txt) => setEmail(txt.target.value)}
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    onChange={(pass) => setPassword(pass.target.value)}
                  />
                </Div>
                <Typography textAlign={"right"} variant={"body1"}>
                  {/* <Link underline="none" _blank href="forgot-password">Forgot your password?</Link> */}
                </Typography>
                <Div sx={{ mb: 1 }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Remember me"
                  />
                </Div>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  onClick={handler}
                  loading={loading}
                >
                  Login
                </LoadingButton>
                {/* <Typography textAlign={"center"} variant={"body1"} mb={1}>Don't have an account?
                                <Link underline="none" href="#">Sign up now</Link>
                            </Typography> */}
              </Div>
              {/* )} */}
            </Formik>
          </CardContent>
        </Card>
        {/* <Typography variant={"body1"} mb={2}>Or sign up with</Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
                <IconButton
                    sx={{
                        bgcolor: '#385196',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                            backgroundColor: '#385196',
                        }
                    }}
                    aria-label="Facebook"
                >
                    <Facebook fontSize={"small"} />
                </IconButton>
                <IconButton
                    sx={{
                        bgcolor: '#00a8ff',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                            backgroundColor: '#00a8ff',
                        }
                    }}
                    aria-label="Twitter"
                >
                    <Twitter fontSize={"small"} />
                </IconButton>
                <IconButton
                    sx={{
                        bgcolor: '#23272b',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                            backgroundColor: '#23272b',
                        }
                    }}
                    aria-label="Twitter"
                >
                    <Google fontSize="small" />
                </IconButton>
            </Stack> */}
      </Div>
    </>
  );
};

export default Login;
