import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllProjectsQuery, BLUE_PRINTS, useGetProjectQuery, useDeleteProjectMutation } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Add from '.';
import { setSelectedProject } from 'app/rkt_query/SelectedStuffReducer';
import ViewProject from './ViewProject';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import AddUpdateProject from './AddUpdateProject';

const ProjectList = () => {
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteProject, responseDelete] = useDeleteProjectMutation();
  const { data: res_get_all, isLoading: isLoadingProjects, error: errorProjects } = useGetAllProjectsQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.project
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedProject(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedProject(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedProject(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedProject(row)); }
  bluePrint.handleDelete = (id) => { deleteProject(id); }

  


  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title={"Projects"}
        wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
        <div style={{ width: '100%' }}>
          <DataTable showAddButton={true} btnTitleAdd="Project"  showLanguageSwitcher={false}  data={res_get_all?.data?.projects} bluePrint={bluePrint} ViewModel={() => <ViewProject />} ModalContent={() => <AddUpdateProject onClose={hideDialog} />} />
         
        </div>
      </JumboDemoCard>
    </>
  )
}


export default ProjectList