import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import {
  Button,
  TextField,
  Fab,
  Box,
  Radio,
  FormControlLabel,
  RadioGroup,
  Grid,
} from "@mui/material";
import {
  selectedUserProjectSelector,
  setSelectedUserProject,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddUserProjectMutation,

  useGetAllProjectsQuery,

  useGetAllUsersQuery,

  useGetUserProjectQuery,
  useUpdateUserProjectMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";


import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import Div from "@jumbo/shared/Div";


const AddUpdateUserProject = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedUserProject = useSelector(selectedUserProjectSelector);
  const { data: res_get_all_projects, isLoading: isLoadingProjects, error: errorProjects } = useGetAllProjectsQuery();
  const { data: res_get_all, isLoading: isLoadingAdmins, error: errorAdmins } = useGetAllUsersQuery();
  const { data: res_get } = useGetUserProjectQuery(selectedUserProject?.id);

  const [updateUserProject, responseUpdate] = useUpdateUserProjectMutation();
  const [addUserProject, responseAdd] = useAddUserProjectMutation();
  const bluePrint = BLUE_PRINTS.userProject;
  const [userProjectState, setUserProjectState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();

  const addUpdateUserProjectData = async (action) => {
    const formData = new FormData();

    formData.append("id", res_get?.data?.user_project?.id);
    formData.append("project_id", userProjectState.project_id);
    formData.append("user_id", userProjectState.user_id);
    formData.append("language_id", userProjectState.language_id);
    formData.append("role_id", userProjectState.role_id);
  

    const res =
      action === "add"
        ? await addUserProject(formData)
        : await updateUserProject(formData);

    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message, "error");
    }
  };
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);


  useEffect(() => {
    setUserProjectState(selectedUserProject ? res_get?.data?.user_project : bluePrint);
  }, [res_get]);
  const project_languages = res_get_all_projects?.data?.projects
  ?.filter(project => project?.id == userProjectState?.project_id)
  ?.flatMap(project => project?.languages?.map(languageObj => languageObj.language));

// const project_languages=res_get_all_projects?.data?.projects?.filter((project)=>project?.id==userProjectState?.project_id)?.map((project)=>project?.languages)

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {userProjectState ? (
        <div style={addUpdateDivStyleGlobal}>
         

        
       
     

          <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZDropdown
                error={error?.project_id}
                value={userProjectState?.project_id || ""}
                data={res_get_all_projects?.data?.projects}
                onChange={(e) => {
                  setUserProjectState((x) => ({
                    ...x,
                    project_id: e.target.value,
                  }));
                }}
                
                placeholder="Select Project"
              />
            </Div>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZDropdown
                error={error?.language_id}
                value={userProjectState?.language_id || ""}
                data={project_languages}
                onChange={(e) => {
                  setUserProjectState((x) => ({
                    ...x,
                    language_id: e.target.value,
                  }));
                }}
                
                placeholder="Select Language"
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZDropdown
                error={error?.user_id}
                value={userProjectState?.user_id || ""}
                data={res_get_all?.data?.users}
                onChange={(e) => {
                  setUserProjectState((x) => ({
                    ...x,
                    user_id: e.target.value,
                  }));
                }}
                fieldTextName="email"
                placeholder="Select User"
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZDropdown
                error={error?.role_id}
                value={userProjectState?.role_id || ""}
                data={res_get_all?.data?.roles}
                onChange={(e) => {
                  setUserProjectState((x) => ({
                    ...x,
                    role_id: e.target.value,
                  }));
                }}
              
                placeholder="Select User Role"
              />
            </Div>
          </Grid>

     

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedUserProject(null));
              }}
            >
              Cancel
            </Button>
            {selectedUserProject?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateUserProjectData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateUserProjectData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedUserProject(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateUserProject;
