import { selectedProjectSelector } from 'app/rkt_query/SelectedStuffReducer';
import {  useGetProjectQuery } from 'app/rkt_query/storeApis';
import React from 'react'
import { useSelector } from 'react-redux';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewProject = () => {

    const selectedProject = useSelector(selectedProjectSelector);
    const { data: res_get } = useGetProjectQuery(selectedProject?.id);
    const projectArray =
        [
            { label: 'User Name', data: res_get?.data?.project?.user?.name },
            { label: 'User Email', data: res_get?.data?.project?.user?.email },
            { label: 'Project', data: res_get?.data?.project?.name },
        ]

    return (
        <div>

            {
                  selectedProject?.id ?
                        <DZGenericView obj={projectArray} closeBtn={true}  /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewProject

